<template>
<section class="domain_content">
    <h1>
        GB WhatsApp APK Download Page
    </h1>

    <p>
        Follow the steps below to download GB WhatsApp safely:
    </p>
    <ol>
        <li>Make sure your phone is available for downloading unknown sources. As we all know, GB WhatsApp is a modified app.</li>
        <li>Make sure you're using an Android phone. GB WhatsApp is not available for iOS.</li>
        <li>Click the button below to download GB WhatsApp, and then follow the installation guide.</li>
        <li>If you need to download the latest version, search "gb whatsapp" on Google Browser and find our site: <a href="/">https://gbwhatsapk.org/</a></li>
    </ol>

    <div exp-data="show_gbhome_download" class="big-download-btn top-download fix has-exp" data-exposure="1" @click="download(apk)">
        <img :src="downloadicon" alt="download" class="download-img">
        <div class="download-name">
            Download GB WhatsApp Latest Version
        </div>
    </div>

    <h2>GB WhatsApp APK Info</h2>

    <table cellspacing="0">
        <tr>
            <th>App Name</th>
            <th>GBWhatsApp</th>
        </tr>
        <tr>
            <td>Version</td>
            <td>V18.10</td>
        </tr>
        <tr>
            <td>License</td>
            <td>Free</td>
        </tr>
        <tr>
            <td>Size</td>
            <td>77M</td>
        </tr>
        <tr>
            <td>Langugage</td>
            <td>60+</td>
        </tr>
        <tr>
            <td>Operating system</td>
            <td>Android</td>
        </tr>
        <tr>
            <td>Update Time</td>
            <td>1 days ago</td>
        </tr>
    </table>

</section>
</template>

<script>
import '@/css/default.scss';
import {
    mapGetters
} from 'vuex';

export default {
    ...{
        "metaInfo": {
            "title": "GB WhatsApp APK Download Page with Anti-Ban Enhanced",
            "meta": [{
                    "name": "description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }, {
                    "name": "title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "name": "keywords",
                    "content": "gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download"
                },
                {
                    "property": "og:title",
                    "content": "GB WhatsApp APK Download Page with Anti-Ban Enhanced"
                }, {
                    "property": "og:description",
                    "content": "Download GB WhatsApp APK (Anti-Ban) New Version for Android"
                }
            ],
            "link": [{
                "rel": "canonical",
                "href": "https://gbwhatsapk.org/download-gbwhatsapp/"
            }]
        }
    },
    data() {
        return {
            from: null,
            filename: null,
            partners: null,
            updateTime: null,
            clicks: null,
            pageIntSuccess: null,
            apk: null,
        };
    },
    computed: {
        ...mapGetters([
            'showDebug',
            'host',
            "downloadicon"
        ])
    },
    created() {
        this.initParams();
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        download(apk) {
            this.$global.download(apk);
        },
        initParams() {
            this.from = this.$global.GetQueryString('from');
            this.filename = this.$global.GetQueryString('filename');
        },
        fetchData() {
            if (this.from) {
                this.getAPK(true, this.from, this.filename);
            } else {
                this.getAPK();
                this.getOfficialApk();
            }
        },
        updateAPK() {
            this.$emit('update-apk', this.apk);
        },
        getAPK(isFromQuery = false, from = 'gb', filename = 'gb') {
            this.$server
                .getAPPInfo({
                    promotionChannel: from,
                    promotionName: filename
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.partners = res.data.partners;
                        this.updateTime = res.data.updateTime;
                        this.clicks = res.data.clicks;
                        this.pageIntSuccess = true;
                        if (isFromQuery) {
                            this.apk = res.data.apk;
                            this.updateAPK();
                        }
                    }
                })
                .catch((err) => {
                    console.error('Error fetching APK:', err);
                });
        },
        getOfficialApk() {
            this.$server
                .getOfficialApk({
                    domain: 'gbpro.download',
                    appName: 'GB_gbpro.download'
                })
                .then((res) => {
                    if (res.code === 200) {
                        this.apk = res.data.apk;
                        this.updateAPK();
                        console.log('APK fetched successfully');
                    }
                })
                .catch((err) => {
                    console.error('Error fetching official APK:', err);
                });
        }
    }
};
</script>
